$(document).on('click', '[data-image]', function (e) {

    e.preventDefault();

    var element = $(this);
    insertImage(element);
});

function insertImage(element) {

    var container = element;
    var video = '<a href="' + element.data('image') + '" target="_blank"><img src="' + element.data('image') + '" class="img-thumbnail"></a>';

    if (element.data('container') != undefined) {
        container = $(element.data('container'));
    }

    if (element.data('removeitem') != undefined) {
        $(element.data('removeitem')).remove();
    }

    if (element.data('replacecontent') != undefined) {
        container.html(video);
    }
    else {
        container.append(video);
    }

    if (element.data('removeitem') != undefined) {
        container = $(element.data('container')).find(element.data('removeitem')).remove();
    }
}