$(document).ready(function () {

    // Animate the scroll to top
    $('.anchor').on('click', function (event) {
        event.preventDefault();
        var hash = $(this).data('href');

        $('html, body').animate({scrollTop: $(hash).offset().top}, 300, function () {
            //window.location.hash = hash;
        });
    });

    $('body').tooltip({
        selector: '[data-toggle="tooltip"]'
    });

    $('#main-navigation .dropdown-menu').bind('click', function (e) {
        e.stopPropagation()
    })

    var $tabs = $('.nav-tabs li');

    $('#prevtab:not(.skip), .prevtab:not(.skip)').on('click', function () {
        $tabs.filter('.active').prevAll('li:not(.skip)').first().find('a[data-toggle="tab"]').tab('show');
    });

    $('#nexttab:not(.skip), .nexttab:not(.skip)').on('click', function () {
        $tabs.filter('.active').nextAll('li:not(.skip)').first().find('a[data-toggle="tab"]').tab('show');
    });

    $(".nav .disabled").on("click", function (e) {
        e.preventDefault();
        return false;
    });

    $(".vertical-scroll").mCustomScrollbar({
        scrollButtons: {
            enable: false
        },
        scrollInertia: 200,
        advanced: {autoExpandVerticalScroll: true, updateOnContentResize: true},
        mouseWheelPixels: "auto"
    });
    $(".horizontal-scroll").mCustomScrollbar({
        horizontalScroll: true,
        scrollButtons: {
            enable: false
        },
        scrollInertia: 200,
        advanced: {autoExpandHorizontalScroll: true, updateOnContentResize: true}
    });
});

$(window).scroll(function () {
    if ($(this).scrollTop() > 500) {
        $('.go-top').addClass('show');
    }
    else {
        $('.go-top').removeClass('show');
    }

    $('body').css('background-position', "center " + (-window.pageYOffset / 8) + "px");
    $('.header-paralax').css('background-position', "center " + (-window.pageYOffset / 2) + "px");

    $('.paralax').each(function () {
        var position = (-window.pageYOffset) + $(this).offset().top;

        position = -(position / 8);

        if (position >= ($(this).height() + $(this).height() / 2)) {
            position = 'bottom';
        }
        else if (position < -($(this).height() / 2)) {
            position = 'top';
        }
        else {
            position += "px";
        }

        console.log(position);

        $(this).css('background-position', "center " + position);
    });
});

$(document).on('click', '[data-remove-remember]', function (e) {
    $.cookie($(this).data('remove-remember'), '-1', {expires: 9999, path: '/', secure: false});
});

(function ($, window) {
    $.fn.center = function () {

        return this.each(function () {
            // Store the jQuery object for future reference
            var element = $(this);

            var parent = element.data('parent');

            function center() {
                element.css("position", "absolute");
                element.css("top", Math.max(0, (($(parent).height() - $(element).outerHeight()) / 2) +
                        $(parent).scrollTop()) + "px");
                element.css("left", Math.max(0, (($(parent).width() - $(element).outerWidth()) / 2) +
                        $(parent).scrollLeft()) + "px");
            }

            center();

            $(window).resize(function () {
                center()
            });

            return element;
        });

    };

    // outside the scope of the jQuery plugin to
    // keep track of all dropdowns
    var $allDropdowns = $();

    // if instantlyCloseOthers is true, then it will instantly
    // shut other nav items when a new one is hovered over
    $.fn.dropdownHover = function (options) {

        // the element we really care about
        // is the dropdown-toggle's parent
        $allDropdowns = $allDropdowns.add(this.parent());

        return this.each(function () {
            var $this = $(this),
                $parent = $this.parent(),
                defaults = {
                    delay: 500,
                    instantlyCloseOthers: true
                },
                data = {
                    delay: $(this).data('delay'),
                    instantlyCloseOthers: $(this).data('close-others')
                },
                settings = $.extend(true, {}, defaults, options, data),
                timeout;

            $parent.hover(function (event) {

                // so a neighbor can't open the dropdown
                if (!$parent.hasClass('open') && !$this.is(event.target)) {
                    return true;
                }

                if (settings.instantlyCloseOthers === true) {
                    $allDropdowns.removeClass('open');
                }

                window.clearTimeout(timeout);
                $parent.addClass('open');
            }, function () {
                timeout = window.setTimeout(function () {
                    $parent.removeClass('open');
                }, settings.delay);
            });

            // this helps with button groups!
            $this.hover(function () {
                if (settings.instantlyCloseOthers === true) {
                    $allDropdowns.removeClass('open');
                }

                window.clearTimeout(timeout);
                $parent.addClass('open');
            });

            // handle submenus
            $parent.find('.dropdown-submenu').each(function () {
                var $this = $(this);
                var subTimeout;

                $this.hover(function () {

                    window.clearTimeout(subTimeout);
                    $this.children('.dropdown-menu').show();
                    // always close submenu siblings instantly

                    $this.siblings().children('.dropdown-menu').hide();
                }, function () {
                    var $submenu = $this.children('.dropdown-menu');
                    subTimeout = window.setTimeout(function () {

                        $submenu.hide();
                    }, settings.delay);
                });
            });
        });
    };

})(jQuery, window, document);

$('.carousel').on('slide.bs.carousel', function () {

    $(this).find('iframe').remove();
});

$(document).delegate('*[data-toggle="lightbox"]', 'click', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(document).delegate('*[data-toggle="lightbox-hover"]', 'mouseover', function (event) {
    event.preventDefault();
    $(this).ekkoLightbox();
});

$(document).delegate('*[data-remote]', 'mouseenter', function (event) {
    var element = $(this);

    var remoteData = element.attr('data-content');

    function showPopup(data) {
        element.popover({
            html: true,
            content: data,
            placement: 'auto top',
            class: 'unit-popover',
            container: 'body',
            template: '<div class="popover unit-popover" role="tooltip"><div class="arrow"></div><div class="popover-content"></div></div>',
            delay: {
                show: "500",
                hide: "100"
            }
        }).popover('show')
    }

    setTimeout(function(){

        if(element.is(":hover")){

            if (!remoteData || element.attr('data-group-cached') != element.attr('data-group')) {
                element.attr('data-content', '');
                showPopup('<i class="fa fa-circle-o-notch fa-spin text-white"></i>');

                $.ajax({
                    url: element.attr('data-remote'),
                    dataType: "html",
                    cache: true,
                    success: function (data) {
                        element.attr('data-content', data);
                        showPopup(data);
                        element.attr('data-group-cached', element.attr('data-group'));
                    }
                });
            } else {
                showPopup(remoteData);
            }

            element.mouseleave(function () {
                $('.popover:visible').popover('destroy')
            });
        }

    }, 500);
});

// Animate the scroll to top
$('.anchor-link').click(function (event) {
    event.preventDefault();
    var hash = $(this).attr('href');
    $('html, body').animate({scrollTop: $(hash).offset().top}, 300, function () {
        window.location.hash = hash;
    });
});

$(window).load(function () {
    $('.js-center').center();
});

(function (i, s, o, g, r, a, m) {
    i['GoogleAnalyticsObject'] = r;
    i[r] = i[r] || function () {
            (i[r].q = i[r].q || []).push(arguments)
        }, i[r].l = 1 * new Date();
    a = s.createElement(o),
        m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m)
})(window, document, 'script', '//www.google-analytics.com/analytics.js', 'ga');

ga('create', 'UA-2014915-14', 'arma3.com');
ga('send', 'pageview');