$(document).on('click', '[data-video]', function (e) {

    e.preventDefault();

    var element = $(this);

    if ($('#modal-ageGate').length) {
        $('#modal-ageGate').modal('show');

        $('.btn-age-check').on('click', function () {

            var ageData = {
                day: $('#age-day').val(),
                month: $('#age-month').val(),
                year: $('#age-year').val()
            };

            $('#modal-ageGate').modal('hide');

            if (checkAge(ageData)) {
                insertVideo(element);

                setTimeout(function () {
                    $('#modal-ageGate-forbidden').remove();
                }, 500)
            }
            else {
                $('#modal-ageGate-forbidden').modal('show');
            }

            setTimeout(function () {
                $('#modal-ageGate').remove();
            }, 500)

        });
    }
    else {

        if ($('#modal-ageGate-forbidden').length) {
            $('#modal-ageGate-forbidden').modal('show');
        }
        else {
            insertVideo(element);

        }
    }
});

function insertVideo(element) {

    var container = element;
    var video = '<iframe frameborder="0" height="100%" src="//www.youtube.com/embed/' + element.data('video') + '?autoplay=1&amp;enablejsapi=1&amp;autohide=1&amp;showinfo=0&amp;rel=0&amp;playlist=0" width="100%" allowfullscreen frameborder="0"></iframe>';

    if(element.data('external')){
        video += '<a href="'+ element.attr('href') +'" target="_blank" class="btn btn-black btn-lg" style="position: absolute;right: 15px;top: 15px;"><span class="glyphicon glyphicon-new-window"></span> WATCH ON YOUTUBE</a>';
    }

    if (element.data('container') != undefined) {
        container = $(element.data('container'));
    }

    if (element.data('removeitem') != undefined) {
        $(element.data('removeitem')).remove();
    }

    if (element.data('replacecontent') != undefined) {
        container.html(video);
    }
    else {
        container.append(video);
    }

    if (element.data('removeitem') != undefined) {
        container = $(element.data('container')).find(element.data('removeitem')).remove();
    }
}

function checkAge(ageData) {

    var today = new Date();
    var nowyear = today.getFullYear();
    var nowmonth = today.getMonth();
    var nowday = today.getDate();

    var birthyear = parseInt(ageData.year);
    var birthmonth = parseInt(ageData.month);
    var birthday = parseInt(ageData.day);

    var age = nowyear - birthyear;
    var age_month = nowmonth - birthmonth;
    var age_day = nowday - birthday;

    if (age_month < 0 || (age_month == 0 && age_day < 0)) {
        age = parseInt(age) - 1;
    }

    if (age < 18) {

        $.cookie('arma3_age_gate', '-1', {expires: 365, path: '/', secure: false});

        return false;
    }
    else {

        $.cookie('arma3_age_gate', '1', {expires: 365, path: '/', secure: false});

        return true;
    }

}